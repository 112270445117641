<template>
  <ion-page>
    <ion-content  :full-screens="true">

        <Header title="Navidad 12+1"></Header>

        <div class="container">
            <section id="options">
                <div class="box" style="padding-bottom: 0rem">
                    <div class="list-nav">
                        <ion-grid>
                            <ion-row>
                                <ion-col size="12" size-lg="6">
                                    <h1>Nuevo Pedido</h1>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </div>
            </section>

            <section id="tpvs">
                <div class="box">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="6" size-sm="4" size-md="3" size-lg="2" size-xl="2" class="tile">
                                <ion-card @click="openDetails({component: 'navidad121-menuNavidad', data: {}})">
                                    <ion-card-content>
                                        <div class="thumbnail">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/docemasunorestaurante-com.appspot.com/o/web%2Fnavidad%2Fthumbnails%2Fmenu1_256x256.webp?alt=media" alt="Menu de Navidad"/>
                                        </div>
                                        <h5>Menu de Navidad</h5>
                                    </ion-card-content>
                                </ion-card>
                            </ion-col>
                            <ion-col size="6" size-sm="4" size-md="3" size-lg="2" size-xl="2" class="tile">
                                <ion-card  @click="openDetails({component: 'navidad121-cartaNavidad', data: {}})">
                                <ion-card-content>
                                    <div class="thumbnail">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/docemasunorestaurante-com.appspot.com/o/web%2Fnavidad%2Fthumbnails%2Fcarta1_256x256.webp?alt=media" alt="Carta de Navidad"/>
                                    </div>
                                    <h5>Carta de Navidad</h5>
                                </ion-card-content>
                                </ion-card>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </section>
        </div>


      <Footer></Footer>
    </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonCard, IonCardContent } from '@ionic/vue';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'Products',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    Header,
    IonCard,
    IonCardContent 
  },
    data() {
      return {
          selectedCategory: undefined
      };
    },
    computed: {
      ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions(['openDetails']),
    },
});
</script>

<style scoped>
  .tile{
    padding: 0.5rem;
  }

  .tile ion-card{
    display: flex;
    align-items: flex-end;
    background: transparent !important;
  }

    .tile ion-card ion-card-content{
        width: 100%;
        padding: 0 !important;
    }

    .tile ion-card h5{
        cursor: pointer;
    }

    .tile ion-card .thumbnail{
        background-color: var(--ion-color-light);
        width: 100%;
        padding-top: 100%; /* 1:1 Aspect Ratio */
        position: relative; /* If you want text inside of it */
        margin-bottom: 1rem;
        border-radius: 16px;
        cursor: pointer;
    }

    .tile ion-card .thumbnail img{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 16px;
    }

</style>